<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Alert :message="requestError" type="alert-danger" v-if="requestError" />

    <div class="card" v-if="loading">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-center">
          <div class="spinner-border m-5" role="status">
            <span class="visually-hidden"></span>
          </div>
        </div>
      </div>
    </div>

    <div class="card" v-if="!loading && !success">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-center my-5">
          <p>No Resource Found. Try Again</p>
        </div>
      </div>
    </div>

    <div class="row " v-if="!loading && success">
      <div class="col-md-12 mb-4">
        <h4 class="card-title my-3 float-right">
          <router-link
            :to="`/user/${$route.params.id}/enroll_courses`"
            class="btn btn-success btn-sm"
            >My Courses</router-link
          >
        </h4>
      </div>
    </div>
    <div
      class="container card content_margin_bottom pt-4"
      v-if="!loading && success"
    >
      <div class="table-wrapper">
        <table class="table table-borderless">
          <thead>
            <tr>
              <td scope="col"><b>Full Name</b></td>
              <td scope="col">
                {{ technician.technician.first_name }}
                {{ technician.technician.other_names }}
                {{ technician.technician.last_name }}
              </td>
            </tr>
            <tr>
              <td scope="col"><b>Email</b></td>
              <td scope="col">{{ technician.technician.email }}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><b>Number of Certificates</b></td>
              <td>{{ technician.technician.student_cert.length }}</td>
            </tr>
          </tbody>
        </table>

        <div class="d-flex justify-content-end m-2">
          <b-button class="btn-success " @click="sendRequest(technician.technician.id)">
            Hire
          </b-button>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
/* eslint-disable no-unused-vars */
import toasterMixin from "@/mixins/toasterMixin.js";
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page-header";
import Alert from "@/components/Alert.vue";
import appConfig from "@/app.config";
import store from "@/state/store";

export default {
  name: "User Course Details",
  components: {
    Layout,
    PageHeader,
    Alert,
  },
  page: {
    title: "Technician Details",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Technician Details",
      items: [
        {
          text: "User",
        },
        {
          text: "Technician Details",
          active: true,
        },
      ],
      technician: {},
      userId: this.$route.params.id,
      requestError: null,
      technicianId: this.$route.params.technicianId,
      loading: false,
      success: false,
      showCert: false,
    };
  },
  methods: {
    sendRequest(enrollmentId) {
      const data = {
        recruiter_id: this.userId,
        technician_id: enrollmentId,
      };

      store
        .dispatch("hiring/getHire", data)
        .then((response) => {
          console.log(response, "things");
          this.fetchData();
        })
        .catch((err) => {
        
          this.loading = false;
          this.success = false;
        });
    },
    toCert(certs, enrollmentId) {
      let certId = certs.find((cert) => cert.enrollment === enrollmentId);

      store
        .dispatch("enroll/eCertDownload", certId.id)
        .then((response) => {
          const content = response.headers["content-type"];
        })
        .catch(() => {
          this.loading = false;
          this.success = false;
        });
    },

    fetchData() {
      this.loading = true;
      store
        .dispatch("hiring/retrieveHire", this.technicianId)
        .then((response) => {
          console.log(response.data);
          const technician = response.data;
          this.technician = technician;
          this.loading = false;
          this.success = true;
        })
        .catch(() => {
          this.requestError = "An error occured";
          this.loading = false;
          this.success = false;
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped>
b {
  font-weight: bolder;
}
.table-wrapper {
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.247);
}
</style>
